<template>
	<div class="bulk_box">
		<div class="null-page" v-show="yes"></div>
		
		<el-card class="box-card order-list">
			<div slot="header" class="clearfix"><span>发货订单</span></div>

			<el-tabs v-model="orderStatus" @tab-click="handleClick">
				<el-tab-pane label="全部订单" name="all"></el-tab-pane>
				<el-tab-pane label="待发货" name="0"></el-tab-pane>
				<el-tab-pane label="待收货" name="1"></el-tab-pane>
				<el-tab-pane label="交易成功" name="2"></el-tab-pane>
			</el-tabs>
			<div class="search_area">
				<div>
					合同号：
					<input type="text" placeholder="请输入合同号" v-model="plan_order_no">
					下单时间：
					<el-date-picker @change="changeTimeLimit" v-model="date_limit" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
					<div class="btn" @click="refresh">搜索</div>
				</div>
			</div>
			<div v-loading="loading">
				<nav>
					<li>商品信息</li>
					<li>合同件数</li>
					<li>期数</li>
					<li>逾期状态</li>
					<li>订单状态</li>
					<li>操作</li>
				</nav>
				<div class="list" v-if="orderList.length > 0">
					<div class="item" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
						<div class="head">
							<span class="create-time">计划时间：{{ $util.timeStampTurnTime(orderItem.plan_time) }}</span>
							<span class="order-no">合同号：{{ orderItem.plan_order_no }}</span>
							<!-- <router-link :to="'/shop-' + orderItem.site_id" target="_blank">{{ orderItem.site_name }}</router-link> -->
							<!-- <span class="order-type">{{ orderItem.order_type_name }}</span> -->
							<span class="order-type">下单人：{{ orderItem.nickname }}</span>
						</div>
						<ul v-for="(goodsItem, goodsIndex) in orderItem.order_goods" :key="goodsIndex">
							<li>
								<div class="img-wrap" @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">
									<img :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(orderIndex, goodsIndex)" />
								</div>
								<div class="info-wrap" @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">
									<h5>{{ goodsItem.sku_name }}</h5>
									<!-- <span>规格：规格值</span> -->
								</div>
							</li>
							<li>
								<span>{{ goodsItem.delivery_num }}件</span>
							</li>
							<template v-if="goodsIndex == 0">
								<li>第{{orderItem.periods}}期
								</li>
								<li>{{orderItem.is_overdue ? '已逾期' :'未逾期'}}
								</li>
								<li>
									<span class="ns-text-color">{{ orderItem.order_status_name }}</span>
									<!-- <el-link :underline="false" @click="orderDetail(orderItem)">订单详情</el-link> -->
								</li>
								<li>
									<el-button type="primary" size="mini" :plain="true"
										@click="orderDetail(orderItem)">
										订单详情
									</el-button>
									<template v-if="orderItem.order_status == 1">
										<el-button type="primary" size="mini" :plain="true"
											@click="confirmDlivery(orderItem)">
											确认收货
										</el-button>
									</template>
								</li>
							</template>
						</ul>
					</div>
				</div>
				<div v-else-if="!loading && orderList.length == 0" class="empty-wrap">暂无相关订单</div>
			</div>
			<div class="pager">
				<el-pagination 
					background 
					:pager-count="5" 
					:total="total" 
					prev-text="上一页" 
					next-text="下一页" 
					:current-page.sync="currentPage" 
					:page-size.sync="pageSize" 
					@size-change="handlePageSizeChange" 
					@current-change="handleCurrentPageChange" 
					hide-on-single-page
				></el-pagination>
			</div>
		</el-card>
		<el-dialog :visible.sync="receipt_dialog" :show-close="false" class="add_dialog">
			<div class="add_content">
				<div class="tit">收货信息</div>
				<div class="condition">
					<div class="condition_item">
						<div class="left">
							*<div class="name">收货单号：</div>
						</div>
						<div class="right">
							<input type="text" placeholder="请输入收货单号" v-model="receipt_no">
						</div>
					</div>
				</div>
				<div class="btns">
					<div class="btn save" @click="confirmReceive()">保存</div>
					<div class="btn" @click="receipt_dialog = false">返回</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { shippingPlamOrderPageList, confirmDlivery } from '@/api/bulkOrder';
import orderMethod from '@/utils/orderMethod';
export default {
	name: 'order_list',
	components: {},
	data: () => {
		return {
			loading: true,
			orderList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			yes: true,
			start_time:'',
			end_time:'',
			plan_order_no:'',
			name:'',
			mobile:'',
			date_limit:'',
			receipt_no:'',//收货单号
			receipt_dialog:false,
			orderStatus: 'all',
			active_order:{},
		};
	},
	mixins: [orderMethod],
	created() {
		this.orderStatus = this.$route.query.status || 'all';
		this.getOrderList();
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	methods: {
		confirmReceive(){
			confirmDlivery({
				order_id:this.active_order.order_id,
				periods:this.active_order.periods,
				receipt_no:this.receipt_no,
			})
			.then(res=>{
				if(res.code>=0){
					this.$message({message:'收货成功'});
					this.receipt_dialog = false;
					this.refresh();
				}else{
					this.$message.error(res.message);
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
		},
		confirmDlivery(orderData){
			this.active_order = orderData;
			this.receipt_no = '';
			this.receipt_dialog = true;
		},
		calcPercent(part,total){
			if(total){
				return Number(((part/total)*100).toFixed(2));
			}else{
				return Number(0);
			}
		},
		changeTimeLimit(e){
			if(e){
				this.start_time = e[0];
				this.end_time = e[1];
			}
		},
		handleClick(tab, event) {
			this.currentPage = 1;
			this.orderStatus = tab.name;
			this.refresh();
		},
		getOrderList() {
			shippingPlamOrderPageList({
				page: this.currentPage,
				page_size: this.pageSize,
				start_time:this.start_time,
				end_time:this.end_time,
				plan_order_no:this.plan_order_no,
				order_status: this.orderStatus=='all'?'':this.orderStatus
				
			})
				.then(res => {
					let list = [];
					if (res.code == 0 && res.data) {
						list = res.data.list;
						this.total = res.data.count;
					}
					this.orderList = list;
					this.loading = false;
				})
				.catch(res => {
					this.loading = false;
				});
		},
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		refresh() {
			this.loading = true;
			this.getOrderList();
		},
		operation(action, orderData) {
			let index = this.status;
			switch (action) {
				case 'orderPay': // 支付
					this.orderPay(orderData);
					break;
				case 'orderClose': //关闭
					this.orderClose(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'memberTakeDelivery': //收货
				console.log('9999')
					this.orderDelivery(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'trace': //查看物流
					this.$router.push({ path: '/member/logistics', query: { order_id: orderData.order_id } });
					break;
				case 'memberOrderEvaluation': //评价
					this.$router.pushToTab({ path: '/evaluate', query: { order_id: orderData.order_id } });
					break;
				case 'extendTakeDelivery': //延长收货
					this.orderExtendDelivery(orderData, () => {
						this.refresh();
					});
					break;
			}
		},
		orderDetail(data) {
			this.$router.push('/member/delivery_detail?order_id='+data.order_id+'&periods='+data.periods)
		},
		imageError(orderIndex, goodsIndex) {
			this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>
<style lang="scss">
	.add_dialog {
		.el-dialog__body{
			padding: 0;
			.add_content{
				padding: 8px 44px 41px;
				.tit{
					color: #111;
					font-size: 16px;
					line-height: 22px;
					font-weight: 600;
					margin-bottom: 24px;
				}
				.condition{
					padding-left: 33px;
					.condition_item{
						display: flex;
						align-items: center;
						margin-bottom: 20px;
						.left{
							display: flex;
							align-items: center;
							color: #E02020;
							font-size: 12px;
							line-height: 16px;
							min-width: 70px;
							margin-right: 2px;
							justify-content: flex-end;
							.name{
								margin-left: 5px;
								color: #222;
								font-size: 12px;
								line-height: 16px;
							}
						}
						.right{
							flex:1;
							input{
								width: 45%;
								outline: none;
								border: 1px solid #DBDCDC;
								padding: 8px 12px;
								font-size: 12px;
								line-height: 16px;
								color:#333;
								&::placeholder{
									color: #999;
								}
							}
						}
					}
				}
				.btns{
					margin-top: 12px;
					display: flex;
					align-items: center;
					padding-left: 104px;
					.btn{
						cursor: pointer;
						margin-right: 24px;
						color: #333;
						font-size: 14px;
						line-height: 16px;
						padding: 9px 20px;
						border-radius: 2px;
						border: 1px solid #DBDCDC;
						&.save{
							background-color: #007AFF;
							color: #fff;
						}
					}
				}
			}
		}
	}
	.search_area{
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		>div{
			display: flex;
			align-items: center;
			margin-bottom: 12px;
		}
		.el-date-editor{
			border: 1px solid #E0E0E0;
			border-radius: 0;
			height: auto;
			padding: 8px 10px;
			.el-range-separator{
				line-height: 20px;
			}
			.el-input__icon{
				line-height: 1;
			}
			.el-range-input{
				border: none;
				color: #333;
				font-size: 14px;
				line-height: 20px;
				padding: 0;
			}
		}
		input{
			border: 1px solid #E0E0E0;
			outline: none;
			color: #333;
			font-size: 14px;
			line-height: 20px;
			padding: 8px 12px;
			margin-right: 12px;
		}
		input::placeholder{
			color: #888;
			font-size: 14px;
			line-height: 20px;
		}
		.btn{
			color: #FFF;
			font-size: 12px;
			line-height: 16px;
			padding: 6px 18px;
			border-radius: 2px;
			background-color: #00A98F;
			margin-left: 10px;
			cursor: pointer;
		}
	}
</style>
<style lang="scss" scoped>
	
.bulk_box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.order-list {
	nav {
		overflow: hidden;
		padding: 10px;
		background: #fff;
		margin-bottom: 10px;
		border-bottom: 1px solid #eeeeee;
		li {
			float: left;
			&:nth-child(1) {
				width: 35%;
			}
			&:nth-child(2) {
				width: 13%;
			}
			&:nth-child(3) {
				width: 13%;
			}
			&:nth-child(4) {
				width: 15%;
			}
			&:nth-child(5) {
				width: 13%;
			}
			&:nth-child(6) {
				width: 10%;
			}
		}
	}
	.list {
		.item {
			margin-bottom: 20px;
			border: 1px solid #eeeeee;
			border-top: 0;
			.head {
				padding: 8px 10px;
				background: #f7f7f7;
				font-size: 12px;

				.create-time {
					margin-right: 15px;
				}

				border-bottom: 1px solid #eeeeee;
				a {
					margin: 0 10px 0 20px;
				}

				.order-type {
					margin-left: 15px;
				}
			}
		}
		ul {
			background-color: #fff;
			padding: 10px;
			overflow: hidden;
			li {
				float: left;
				min-height: 60px;
				// line-height: 60px;
				&:nth-child(1) {
					width: 35%;
					line-height: inherit;
					.img-wrap {
						width: 60px;
						height: 60px;
						float: left;
						margin-right: 10px;
						cursor: pointer;
					}
					.info-wrap {
						margin-left: 70px;
						h5 {
							font-weight: normal;
							font-size: $ns-font-size-base;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							margin-right: 10px;
							display: inline-block;
							cursor: pointer;
							&:hover {
								color: $base-color;
							}
						}
						span {
							font-size: $ns-font-size-sm;
							color: #9a9a9a;
						}
					}
				}
				&:nth-child(2) {
					display: flex;
					align-items: flex-start;
					flex-direction: column;
					width: 13%;
					
				}
				&:nth-child(3) {
					color: #E62032;
					width: 13%;
					padding-right: 10px;
					box-sizing: border-box;
				}
				&:nth-child(4) {
					display: flex;
					align-items: flex-start;
					flex-direction: column;
					width: 15%;
					span{
						&:nth-child(2),
						&:nth-child(3){
							display: flex;
							align-items: center;
							font-size: 13px;
							line-height: 18px;
						}
					}
				}
				&:nth-child(5) {
					width: 13%;
					a {
						display: block;
					}
				}
				&:nth-child(6) {
					width: 10%;
					line-height: initial;
					button {
						margin-left: 0;
						margin-bottom: 10px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
}
</style>
